import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import logo from "../../assets/newImages/logo/dukan-inverse.png";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function Drawer({ drawer, action }) {
  const location = useLocation();
  const isHome = location.pathname == "/";
  const [itemSize, setSize] = useState("0px");
  const [item, setItem] = useState("home");
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      setSize(`${43 * getItems}px`);
      setItem(value);
    }
  };

  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const { t, i18n } = useTranslation();

  useEffect(() => {

    const handleStorageChange = () => {
      setLang(localStorage.getItem("lang"));
    };

    window.addEventListener("click", handleStorageChange);
  }, []);

  function ChangeLang(params) {
    setLang(params);
    localStorage.setItem("lang", params);
    if (params == "🇺🇿 Uz") {
      i18n.changeLanguage("uz");
    }
    if (params == "🇷🇺 Ru") {
      i18n.changeLanguage("ru");
    }
    // if (params == "🇬🇧 En") {
    //   i18n.changeLanguage("en");
    // }
  }

  return (
    <>
      <>
        <div
          onClick={(e) => action(e)}
          className={`off_canvars_overlay ${drawer ? "active" : ""}`}
        ></div>
        <div className="offcanvas_menu">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div
                  className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
                >
                  <div className="canvas_close">
                    <a href="#" onClick={(e) => action(e)}>
                      <i className="fa fa-times"></i>
                    </a>
                  </div>
                  <div className="offcanvas-brand text-center mb-40">
                    <img src={logo} alt="" />
                  </div>
                  <div id="menu" className="text-left ">
                    <ul className="offcanvas_main_menu">
                      <li
                        onClick={(e) => handler(e, "news")}
                        id="news"
                        className="menu-item-has-children active"
                      >
                        <a href={`${isHome ? "#industries" : "/#industries"}`}>
                          {t("industry")}
                        </a>
                      </li>
                      <li
                        onClick={(e) => handler(e, "news")}
                        id="news"
                        className="menu-item-has-children active"
                      >
                        <a href={`${isHome ? "#pricing" : "/#pricing"}`}>
                          {t("prices")}
                        </a>
                      </li>
                      <li
                        onClick={(e) => handler(e, "pages")}
                        id="pages"
                        className="menu-item-has-children active"
                      >
                        <a href={`${isHome ? "/blogs" : "/blogs"}`}>
                          {t("blog")}
                        </a>
                      </li>
                      <li
                        onClick={(e) => handler(e, "contact")}
                        id="contact"
                        className="menu-item-has-children active"
                      >
                        <a href={`${isHome ? "#contact_" : "/#contact_"}`}>
                          {t("contact")}
                        </a>
                      </li>
                      <li
                        onClick={(e) => handler(e, "contact")}
                        id="contact"
                        className="menu-item-has-children active"
                      >
                        <DropdownButton
                          id="dropdown-basic-button"
                          title={lang ? lang : "🇺🇿 Uz"}
                        >
                          <Dropdown.Item
                            onClick={() => ChangeLang("🇺🇿 Uz")}
                            className="text-dark"
                          >
                            🇺🇿 Uz
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => ChangeLang("🇷🇺 Ru")}
                            className="text-dark"
                          >
                            🇷🇺 Ru
                          </Dropdown.Item>
                          {/* <Dropdown.Item
                            href="#/action-3"
                            onClick={() => ChangeLang("🇬🇧 En")}
                            className="text-dark"
                          >
                            🇬🇧 En
                          </Dropdown.Item> */}
                        </DropdownButton>
                      </li>
                    </ul>
                  </div>
                  <div className="offcanvas-social">
                    <ul className="text-center">
                      <li>
                        <a href="https://t.me/dukan_platform" target="blank_">
                          <i className="fa-brands fa-telegram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/dukan_platform/"
                          target="blank_"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/dukan.platform"
                          target="blank_"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/@al-raqam"
                          target="blank_"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/showcase/dukan-platform/"
                          target="blank_"
                        >
                          <i className="fa-brands fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-widget-info footer_mobiel__section">
                    <ul>
                      <li>
                        <a href="mailto:alraqam@al-raqam.com">
                          <i className="fal fa-envelope pe-1"></i>{" "}
                          alraqam@al-raqam.com
                        </a>
                      </li>
                      <li>
                        <a href="tel:+998333138222">
                          <i className="fal fa-phone pe-1"></i> +998 33
                          313-82-22
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fal fa-map-marker-alt pe-1"></i>
                          {t("location")}
                        </a>
                      </li>
                      <li>
                        <a href="https://al-raqam.com/">
                          <i className="fa-solid fa-laptop pe-1" />
                          al-raqam.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default Drawer;
